import React from 'react'
import { graphql, Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import GotoDemoHero from 'components/hero/gotoDemo'
import HsChinaAccessGuideForm from 'components/hubspot/chinaAccessGuideForm'
import { chinaAccessFeatures } from '/static/data/solution.static'
import { chinaCDNList } from '/static/data/cdnBrand.static'

const ChinaAccessPage = () => {
  const CHINA_ACCESS_FEATURE = chinaAccessFeatures()
  const { t } = useTranslation()

  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: t('chinaAccessPage.meta.title'),
            metaDescription: t('chinaAccessPage.meta.description'),
            metaUrl: 'https://www.mlytics.com/features/china-access/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section className="container flex flex-col md:flex-row gap-5 pb-32 pt-24">
          <div className="flex-1">
            <h1 className="font-normal mb-5 text-primary-400 text-lg">{t('chinaAccessPage.section1.subtitle')}</h1>
            <h2 className="leading-snug text-primary-500 text-4xl lg:text-5xl">
              {t('chinaAccessPage.section1.title')}
            </h2>
            <p className="py-10 text-xl text-gray-500">{t('chinaAccessPage.section1.description')}</p>
            <div className="flex flex-col lg:flex-row gap-3 my-1">
              <Link to="/sign-up" className="btn btn-bold btn-orange inline-block" style={{ width: 208 }}>
                {t('button.freeTrial.0')}
              </Link>
              <Link
                to="/lp/get-a-demo/"
                className="btn btn-bold btn-ghost btn-orange inline-block"
                style={{ width: 208 }}
              >
                {t('button.getDemo.0')}
              </Link>
            </div>
          </div>
          <div className="flex flex-1 items-center justify-center">
            <StaticImage
              src="https://www.mlytics.com/wp-content/uploads/2022/09/China_access_hero.webp"
              alt="china content delivery"
              style={{ height: 'auto', maxWidth: '470px' }}
            />
          </div>
        </section>
        <section className="container pb-16">
          <h2 className="mb-5 text-center text-primary-500 text-2.5xl">{t('chinaAccessPage.section2.title')}</h2>
          <div className="flex flex-wrap items-center justify-center">
            {chinaCDNList.map((item) => (
              <img key={item.alt} src={item.src} alt={item.alt} height="80" width="160" />
            ))}
          </div>
        </section>
        <section>
          <iframe width="100%" height="900px" src="https://hubble.mlytics.com/solutions/china-access/basic_test" />
        </section>
        <section>
          <div className="container flex flex-col md:flex-row-reverse gap-10 py-20">
            <div className="flex flex-1 items-center overflow-hidden">
              <img
                src="https://www.mlytics.com/wp-content/uploads/2022/09/China_access_PERFORMANCE.gif"
                alt="GMA"
                style={{ height: 'auto', width: '550px' }}
              />
            </div>
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">{t('chinaAccessPage.section3.subtitle')}</div>
              <h2 className="text-3xl">{t('chinaAccessPage.section3.title')}</h2>
              <p className="pb-7 pt-2 text-gray-500">
                {t('chinaAccessPage.section3.description.0')}
                <br />
                <br />
                {t('chinaAccessPage.section3.description.1')}
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="container flex flex-col md:flex-row gap-10 py-20">
            <div className="flex flex-1 items-center overflow-hidden">
              <StaticImage
                src="https://www.mlytics.com/wp-content/uploads/2022/10/China_access_3.webp"
                alt="China RIM CDN Solution"
                style={{ height: 'auto', maxWidth: '520px' }}
              />
            </div>
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">{t('chinaAccessPage.section4.subtitle')}</div>
              <h2 className="text-3xl">{t('chinaAccessPage.section4.title')}</h2>
              <p className="pb-7 pt-2 text-gray-500">{t('chinaAccessPage.section4.description')}</p>
            </div>
          </div>
        </section>
        <section>
          <div className="container flex flex-col md:flex-row-reverse gap-10 py-20">
            <div className="flex flex-1 items-center overflow-hidden">
              <StaticImage
                src="https://www.mlytics.com/wp-content/uploads/2022/09/China_access_4.webp"
                alt="China CDN Solution"
                style={{ height: 'auto', maxWidth: '550px' }}
              />
            </div>
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">{t('chinaAccessPage.section5.subtitle')}</div>
              <h2 className="text-3xl">{t('chinaAccessPage.section5.title')}</h2>
              <p className="pb-7 pt-2 text-gray-500">
                {t('chinaAccessPage.section5.description.0')}
                <br />
                <br />
                {t('chinaAccessPage.section5.description.1')}
                <br />
                <br />
                {t('chinaAccessPage.section5.description.2')}
              </p>
            </div>
          </div>
        </section>
        <section className="bg-primary-500/5 py-20">
          <div className="container grid grid-cols-1 lg:grid-cols-3 gap-5">
            {CHINA_ACCESS_FEATURE.map((feature) => (
              <div key={feature.title} className="text-center">
                <img src={feature.imageSrc} alt={feature.title} className="mx-auto" />
                <h2 className="text-primary-500 text-2xl">{t(feature.title)}</h2>
                <p className="my-5 text-gray-700">{t(feature.content)}</p>
              </div>
            ))}
          </div>
        </section>
        <section className="bg-primary-500/5 py-20">
          <div className="container flex flex-col-reverse lg:flex-row gap-10">
            <div className="flex-1">
              <HsChinaAccessGuideForm />
            </div>
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">{t('chinaAccessPage.section7.subtitle')}</div>
              <h2 className="mb-3 text-primary-500 text-3xl lg:text-4xl">{t('chinaAccessPage.section7.title')}</h2>
              <p className="mb-10 text-gray-900 text-xl">{t('chinaAccessPage.section7.description')}</p>
              <img
                src="https://www.mlytics.com/wp-content/uploads/2022/10/China_access_whitepaper.svg"
                alt="China access whitepaper"
                style={{ height: 'auto', width: '480px' }}
              />
            </div>
          </div>
        </section>
        <GotoDemoHero />
      </React.Fragment>
    </Layout>
  )
}

export default ChinaAccessPage

export const query = graphql`
  query ($language: String!) {
    allFile(filter: { sourceInstanceName: { eq: "images" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
